'use client';
import React, { useContext, useState, createContext, useEffect, SetStateAction, Dispatch, useRef } from 'react';
import { useSearchParams } from 'next/navigation';
import { ConfigType } from 'app/types';
import { usePrevious } from 'helpers/usePrevious'
import { ManageProfileData, fetchUserOwnDetail } from './service';
import { useLanguage } from 'app/components/providers/languageProvider';

export type customSectionKey = "licenses_and_certifications" | "links" | "video_resume" | "language";

interface MainSection {
	label: string;
	tip: string;
	key: string;
	targetId: string;
}

interface CustomSection {
	label: string;
	tip: string;
	recommend: boolean;
	key: customSectionKey;
	targetId: string;
}

interface ProviderData {
	config?: Partial<ConfigType>;
	profile?: ManageProfileData;
	fetchProfile?: () => void;
	userCustomSectionKeys?: customSectionKey[];
	setUserCustomSectionKeys?: Dispatch<SetStateAction<customSectionKey[]>>;
	userMainSections?: MainSection[];
	setUserMainSections?: Dispatch<SetStateAction<MainSection[]>>;
	userCustomSections?: CustomSection[];
	setUserCustomSections?: Dispatch<SetStateAction<CustomSection[]>>;
	userCustomSectionKeysRef?: React.MutableRefObject<customSectionKey[]>;
	prevSearchParams?: any
}

const MangeProfile = createContext<ProviderData | undefined>(undefined);

interface Props extends React.PropsWithChildren<ProviderData> {
	name?: any;
}

export function MangeProfileProvider({ children, ...props }: Props) {
	const searchParams = useSearchParams();
	const prevSearchParams = usePrevious(searchParams)
	const langDict = useLanguage()
	const menu = langDict.manageResume?.menu

	const mainSections: MainSection[] = [
		{
			"label": menu?.basic_info || 'Basic info',
			"tip": menu?.basic_info_tip,
			"key": "basic_info",
			"targetId": "basicinfo"
		},
		{
			"label": menu?.job_preferences || 'Job preferences',
			"tip": menu?.job_preferences_tip,
			"key": "job_preferences",
			"targetId": "jobpreferences"
		},
		{
			"label": menu?.summary || 'Summary',
			"tip": menu?.summary_tip,
			"key": "summary",
			"targetId": "summary"
		},
		{
			"label": menu?.work_experience || 'Work experience',
			"tip": menu?.work_experience_tip,
			"key": "work_experience",
			"targetId": "workexperience"
		},
		{
			"label": menu?.education || 'Education',
			"tip": menu?.education_tip,
			"key": "education",
			"targetId": "education"
		},
		{
			"label": menu?.skills || 'Skills',
			"tip": menu?.skills_tip,
			"key": "skills",
			"targetId": "skills"
		}
	];

	const customSections: CustomSection[] = [
		{
			"label": menu?.licenses_and_certifications || 'Licenses and certifications',
			"tip": menu?.licenses_and_certifications_tip,
			"recommend": true,
			"key": "licenses_and_certifications",
			"targetId": "licensesandcertifications"
		},
		{
			"label": menu?.links || 'Links',
			"tip": menu?.links_tip,
			"recommend": true,
			"key": "links",
			"targetId": "links"
		},
		{
			"label": menu?.video_resume || 'Video resume',
			"tip": menu?.video_resume_tip,
			"recommend": false,
			"key": "video_resume",
			"targetId": "videoresume"
		},
		{
			"label": menu?.language || 'Language',
			"tip": menu?.language_tip,
			"recommend": false,
			"key": "language",
			"targetId": "language"
		}
	];

	const [profile, setProfile] = useState<ManageProfileData | undefined>(props.profile);
	const [userCustomSectionKeys, setUserCustomSectionKeys] = useState<customSectionKey[]>([]);
	const [userMainSections, setUserMainSections] = useState<MainSection[]>(mainSections);
	const [userCustomSections, setUserCustomSections] = useState<CustomSection[]>([]);

	const fetchProfile = () => {
		fetchUserOwnDetail().then(profile => {
			setProfile(profile.data);
		});
	};

	useEffect(() => {
		if (profile?.id) {
			const { languages = [], video_resumes = [], websites = [], license_certifications = [] } = profile;
			const _userCustomSectionKeys: customSectionKey[] = [];

			if (license_certifications?.length) {
				_userCustomSectionKeys.push('licenses_and_certifications');
			}
			if (websites?.length) {
				_userCustomSectionKeys.push('links');
			}
			if (video_resumes?.length) {
				_userCustomSectionKeys.push('video_resume');
			}
			if (languages?.length) {
				_userCustomSectionKeys.push('language');
			}

			const newMainSections = [...mainSections];
			const newCustomSections = customSections.filter(item => {
				if (_userCustomSectionKeys.includes(item.key)) {
					newMainSections.push(item);
					return false;
				}
				return true;
			});

			setUserMainSections(newMainSections);
			setUserCustomSections(newCustomSections);
			setUserCustomSectionKeys(_userCustomSectionKeys);
		}
	}, [profile]);

	return (
		<MangeProfile.Provider value={{
			...props,
			userMainSections, setUserMainSections,
			userCustomSections, setUserCustomSections,
			userCustomSectionKeys, setUserCustomSectionKeys,
			profile, fetchProfile,
			prevSearchParams
		}}>
			{children}
		</MangeProfile.Provider>
	);
}

export function useManageProfileData() {
	const context = useContext(MangeProfile);
	if (context === undefined) {
		throw new Error('useManageProfileData must be used within a MangeProfileProvider');
	}
	return context;
}
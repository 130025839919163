import configuredAxios from 'helpers/configuredAxios'
import { getCookie, setCookie } from 'helpers/cookies';
import { fetchProfileCompletionService } from 'store/services/users/fetchProfileCompletion';

import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail'
import { updateUserProfileService } from 'store/services/users/updateUserProfile'
import { uploadUserAvatarService } from 'store/services/users/uploadUserAvatar'
import {
  createUserPreferencesService,
  updateUserPreferencesService,
  deleteUserPreferencesService
} from 'store/services/users/addUserPreferences'
import { addUserWorkExperienceService } from 'store/services/users/addUserWorkExperience'
import { updateUserWorkExperienceService } from 'store/services/users/updateUserWorkExperience'
import { deleteUserWorkExperienceService } from 'store/services/users/deleteUserWorkExperience'
import { addUserEducationService } from 'store/services/users/addUserEducation'
import { updateUserEducationService } from 'store/services/users/updateUserEducation'
import { deleteUserEducationService } from 'store/services/users/deleteUserEducation'

export async function fetchUserOwnDetail() {
  try {
    const response = await fetchUserOwnDetailService();
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    return error
  }
}

export async function updateUserProfile({ avatarInfo, ...rest }) {
  try {
    if (avatarInfo) {
      if (avatarInfo.avatar_file) {
        let newFile = avatarInfo.avatar_file
        if (avatarInfo.select_avatar_index) {
          const filename = `icon-avatar-${avatarInfo.select_avatar_index}.png`;
          newFile = new File([avatarInfo.avatar_file], filename, { type: avatarInfo.avatar_file.type });
        }
        await uploadUserAvatarService(newFile).catch((error) => {
          console.log('error', error)
        })
      }
      return await updateUserProfileService(rest)
    } else {
      const response = await updateUserProfileService(rest);
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
      return response
    }
  } catch (error) {
    return error
  }
}

export async function uploadUserAvatar(payload) {
  try {
    const { data } = await uploadUserAvatarService(payload);

    const userCookie = getCookie('user')
    userCookie.avatar = data.data.avatar
    await setCookie('user', userCookie);
  } catch (error) {
    return error
  }
}

export async function fetchProfileCompletion() {
  try {
    const response = await fetchProfileCompletionService();
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    return error
  }
}

export async function fetchGoogleMapLocationList(params) {
  try {
    const axios = configuredAxios('apiGo')
    const queryStr = params.query ? encodeURIComponent(encodeURIComponent(params.query)) : ''
    const response = await axios.get(`/google/textsearch?query=${queryStr}&radius=1000`)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
    return response
  } catch (error) {
    return error
  }
}

export async function updateUserEducation(payload) {
  try {
    if (payload.id) {
      return await updateUserEducationService({ educationId: payload.id, educationData: payload })
    } else {
      return await addUserEducationService({ educationData: payload })
    }
  } catch (error) {
    return error
  }
}

export async function deleteUserEducation(payload) {
  try {
    await deleteUserEducationService({
      educationId: payload.id,
      ...payload
    })
  } catch (error) {
    return error
  }
}

export async function updateUserWorkExperience(payload) {
  try {
    if (payload.id) {
      return await updateUserWorkExperienceService({ workExperienceId: payload.id, workExperienceData: payload })
    } else {
      return await addUserWorkExperienceService({ workExperience: payload })
    }
  } catch (error) {
    return error
  }
}

export async function deleteUserWorkExperience(payload) {
  try {
    await deleteUserWorkExperienceService({
      workExperienceId: payload.id,
      ...payload
    })
  } catch (error) {
    return error
  }
}

export async function updateUserPreferences(payload) {
  try {
    if (payload.id) {
      return await updateUserPreferencesService({
        preferenceId: payload?.id,
        params: payload
      })
    } else {
      return await createUserPreferencesService({
        preferenceId: payload?.id,
        params: payload
      })
    }
  } catch (error) {
    return error
  }
}

export async function deleteUserPreferences(payload) {
  try {
    await deleteUserPreferencesService({
      preferenceId: payload?.id,
      ...payload
    })
  } catch (error) {
    return error
  }
}

export async function fetchSuggestedSkills() {
  try {
    const axios = configuredAxios('jobseeker')
    const response = await axios.get(`/suggested-skill`)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    return error
  }
}

export async function updateSkills(payload) {
  try {
    const axios = configuredAxios('jobseeker')
    if (payload.id) {
      return await axios.patch(`/skills/${payload.id}/update`, payload)
    } else {
      return await axios.post(`/skills/create`, payload)
    }
  } catch (error) {
    return error
  }
}

export async function delSkill(payload) {
  try {
    const axios = configuredAxios('jobseeker')
    await axios.delete(`/skills/${payload.id}/delete`)
  } catch (error) {
    return error
  }
}

export async function updateLanguages(payload) {
  try {
    const axios = configuredAxios('jobseeker')
    if (payload.id) {
      return await axios.patch(`/languages/${payload.id}/update`, payload)
    } else {
      return await axios.post(`/languages/create`, payload)
    }
  } catch (error) {
    return error
  }
}

export async function delLanguage(payload) {
  try {
    const axios = configuredAxios('jobseeker')
    await axios.delete(`/languages/${payload.id}/delete`)
  } catch (error) {
    return error
  }
}

export async function updateWebsites(payload) {
  try {
    const axios = configuredAxios('jobseeker')
    if (payload.id) {
      return await axios.patch(`/websites/${payload.id}/update`, payload)
    } else {
      return await axios.post(`/websites/create`, payload)
    }
  } catch (error) {
    return error
  }
}

export async function delWebsite(payload) {
  try {
    const axios = configuredAxios('jobseeker')
    await axios.delete(`/websites/${payload.id}/delete`)
  } catch (error) {
    return error
  }
}

export async function fetchLicenseCertifications() {
  try {
    const axios = configuredAxios('jobseeker')
    const response = await axios.get(`/license-certifications`)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    return error
  }
}

export async function updateAddress(payload) {
  try {
    const axios = configuredAxios('jobseeker')
    if (payload.id) {
      return await axios.patch(`/home-addresses/${payload.id}/update`, payload)
    } else {
      return await axios.post(`/home-addresses/create`, payload)
    }
  } catch (error) {
    return error
  }
}


export async function updateLicenseCertification(payload) {
  console.log('-----payload-----', payload)
  try {
    const axios = configuredAxios('jobseeker');
    const formData = new FormData();

    // 将 payload 中的所有字段添加到 formData 中
    Object.keys(payload).forEach((key) => {
      if (key !== 'attachment') formData.append(key, payload[key] || '');
    });

    if (!payload.attachment) {
      formData.set('attachment_url', ''); // 清空文件
    }

    // 如果 payload 中有文件对象（如 attachment 字段），也需要添加到 formData 中
    const attachment_ur = payload?.attachment_url || ''
    if (!attachment_ur && payload.attachment) { // 新上传文件才传这个参数
      formData.append('attachment', payload.attachment);
      formData.delete('attachment_url')
    }

    // 打印 FormData 的所有内容
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    if (payload.id) {
      formData.delete('attachment')
      return await axios.patch(`/license-certifications/${payload.id}/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      return await axios.post(`/license-certifications/create`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    }
  } catch (error) {
    return error;
  }
}

export async function delLicenseCertification(payload) {
  try {
    const axios = configuredAxios('jobseeker')
    await axios.delete(`/license-certifications/${payload.id}/delete`)
  } catch (error) {
    return error
  }
}

export interface ManageProfileData {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  gender: null;
  email: string;
  mobile_country_id: number;
  phone_num: string;
  phone_num_without_country_code: string;
  avatar: string;
  avatar_tmm: string;
  location_id: number;
  location: string;
  country_id: number;
  country_key: string;
  country: string;
  description: string;
  xp_lvl_id: number;
  xp_lvl: string;
  is_fb_messenger_active: boolean;
  is_email_verify: boolean;
  is_mobile_verified: boolean;
  status_key: string;
  notice_period_id: number;
  notice_period: string;
  is_profile_completed: boolean;
  is_profile_update_required: boolean;
  ats_user_id: null;
  job_preferences: JobPreference[];
  work_experiences: WorkExperience[];
  educations: Education[];
  resume: Resume;
  resumes: Resume[];
  skills: string[];
  referral_code: string;
  websites: Website[];
  license_certifications: LicenseCertification[];
  birthdate: string;
  working_since: string;
  fcm_token: null;
  fcm_token_web_jobseeker: string;
  fcm_token_web_recruiter: null;
  greeting_message: GreetingMessage;
  is_visible: boolean;
  is_no_disturb: boolean;
  no_disturb_start_time: null;
  no_disturb_end_time: null;
  is_chat_message: boolean;
  last_active_at: string;
  years_of_experience: number;
  email_notification_setting: EmailNotificationSetting;
  active_key: number;
  jobseeker_lack_field_num: null;
  jobseeker_skills: [];
  languages: [];
  user_identity: any;
  is_fresh_graduate: boolean;
  video_resumes: []
}

export interface Education {
  id: number;
  school: string;
  school_id: null;
  degree_id: number;
  degree_key: string;
  degree: string;
  field_of_study: null | string;
  grade: null;
  study_period_from: null | string;
  study_period_to: null | string;
  is_currently_studying: boolean;
  description: null;
  description_html: string | TrustedHTML;
  location_key: string;
  location: string;
  country_key: null;
  country_id: null;
}

export interface EmailNotificationSetting {
  system_email: boolean;
  chat_email: boolean;
  newsletter_email: boolean;
}

export interface GreetingMessage {
  id: number;
  message: string;
}

export interface JobPreference {
  id: number;
  job_title: string;
  location_id: number;
  location_key: string;
  location: string;
  salary_range_from: string;
  salary_range_to: string;
  salary_range: string;
  currency_id: number;
  currency_key: string;
  currency: string;
  job_type_id: number;
  job_type_key: string;
  job_type: string;
  industry_id: number;
  industry_key: string;
  industry: string;
  country_id: number;
  country_key: string;
  country: string;
  notice_period_id: number;
  notice_period: string;
  function_job_title_id: number;
  job_categories: string[];
  function_job_title: string;
}

export interface LicenseCertification {
  id: number;
  title: string;
  issuing_organisation: string;
  is_permanent: boolean;
  issue_date: string;
  expiry_date: string;
  credential_id: string;
  credential_url: string;
}

export interface Resume {
  id: number;
  url: string;
  name: string;
  updated_at: string;
  user_id: number;
}

export interface Website {
  id: number;
  title: string;
  url: string;
  description: string;
}

export interface WorkExperience {
  id: number;
  company: string;
  company_id: null;
  company_industry_id: number;
  company_industry: string;
  job_title: string;
  salary: string;
  currency_id: number;
  working_period_from: string;
  working_period_to: string;
  working_period_duration: string;
  is_currently_work_here: boolean;
  description: string;
  description_html: string;
  location: null;
  country_key: string;
  country: string;
  country_id: number;
  function_job_title_id: number;
  job_categories: any[];
  function_job_title: string;
  skills: any[];
}

import React, { Suspense } from 'react'
import dynamic from 'next/dynamic'

import styles from '../../../page.module.scss'

const MapClient = dynamic(() => import('./Client/index'), { ssr: false })

export type propsType = {
  data: any
  lang: any
}

export interface DialogTitleProps {
  id?: string
  children?: React.ReactNode
  onClose: () => void
}

const Map = ({ data, lang }: propsType) => {
  return (
    <section className={styles.map} id='WorkingLocation'>
      <h5>{lang?.workingLocation}</h5>
      <p>{data?.address}</p>
      <Suspense fallback="Loading..." >
        <MapClient data={data} lang={lang} />
      </Suspense>
    </section>
  )
}

export default Map
